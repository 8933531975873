import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

interface CloseModalIconProps {
  customId?: string;
}

const CloseModalIcon = ({ customId }: CloseModalIconProps) => {
  return (
    <CloseOutlined
      id={`close-modal__button__${customId ? customId : 'close'}`}
      role="display"
      style={{
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: '14px',
        transition: 'color 0.3s',
      }}
    />
  );
};
export default CloseModalIcon;
