import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { useAppSelector } from 'app/store';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './SignUp.scss';
import { ValidationError, ValidationSchema } from 'app/constants';
import PhoneInput from 'react-phone-input-2';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { checkUserInvitationAPI, signUpAPI } from 'app/services/api/auth';
import { LoginPage } from '../LoginPage/Loadable';

const { Text, Title } = Typography;

export function SignUpPage() {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const token = new URLSearchParams(location.search).get('token');
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isUserInvitation, setIsUserInvitation] = React.useState<boolean>(true);

  const history = useHistory();
  const onFinish = async (value: any) => {
    setLoading(true);
    try {
      const res: any = await signUpAPI({
        ...value,
        invitationCode: token,
      });
      openNotificationWithIcon('success', res.message);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/login';
      setLoading(false);
    } catch (error: any) {
      openNotificationWithIcon('error', error.message);
      setLoading(false);
    }
  };

  const checkUserInvitation = async (email: string, token: string) => {
    try {
      const res = await checkUserInvitationAPI(email, token);
      setIsUserInvitation(true);
    } catch (error) {
      setIsUserInvitation(false);
    }
  };

  React.useEffect(() => {
    if (email && token) {
      checkUserInvitation(email, token);
    }
  }, [email, token]);

  return isUserInvitation ? (
    <div className="sign-up">
      <>
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        <Row className="container" justify={'center'} align={'middle'}>
          <Col className="content" sm={18} md={18} lg={12} xs={18}>
            <Card className="card">
              <Row justify={'center'} className="cardrow">
                <Col span={24} className="col_imgHeader">
                  <img src="/Img/Header.png" className="img_header" alt="" />
                </Col>
                <Col sm={16} lg={16} md={16} xs={16} style={{ alignContent: 'center' }}>
                  <Title className="text_title">Sign Up</Title>
                  <Text type="secondary" className="text_header">
                    Please enter your information to create your account
                  </Text>
                  <Form layout="vertical" initialValues={{ remember: true, email: email }} onFinish={onFinish}>
                    <Form.Item
                      label={
                        <label className="label_form" htmlFor="login__account__input">
                          Email
                        </label>
                      }
                      name="email"
                    >
                      <Input
                        disabled
                        id="login__account__input"
                        className="input_username"
                        placeholder="Enter your username"
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <label className="label_form" htmlFor="login__account__input">
                          Username
                        </label>
                      }
                      name="email"
                    >
                      <Input
                        disabled
                        id="login__account__input"
                        className="input_username"
                        placeholder="Enter your username"
                      />
                    </Form.Item>
                    <Row gutter={[12, 0]}>
                      <Col span={12}>
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          required={true}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter First Name',
                            },
                          ]}
                        >
                          <Input id="name-modal__input" className="input_username" placeholder="Enter First Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          required={true}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Last Name',
                            },
                          ]}
                        >
                          <Input id="name-modal__input" className="input_username" placeholder="Enter Last Name" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <Form.Item
                        label="Contact Number"
                        name="contactNumber"
                        required={true}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator() {
                              if (!ValidationSchema.phoneNumber.test(getFieldValue('contactNumber'))) {
                                return Promise.reject(new Error(ValidationError.phoneNumber));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <PhoneInput placeholder="Enter Contact Number" inputClass="contact-number" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        label="Enter Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Password',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || ValidationSchema.checkPassSpecial.test(getFieldValue('password'))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(ValidationError.password));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="At least 8 characters, upper and lower cases, number and special characters" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="confirmPassword"
                        label="Confirm Password"
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm Password',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The new password that you entered does not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="At least 8 characters, upper and lower cases, number and special characters" />
                      </Form.Item>
                    </Col>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        id="login__signin__button"
                        className="login_button mt-20"
                        loading={loading}
                      >
                        <Text className="text_login_button">Sign Up</Text>
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} className="card_footer">
                  <img src="/Img/Footer.png" className="img_footer" alt="" />
                </Col>
                <Text className="text_footer">© Copyright PinnacleQM 2014 - 2024. All Rights Reserved</Text>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    </div>
  ) : (
    history.push('/login')
  );
}
