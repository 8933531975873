import { Button, Col, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/store';
import React from 'react';
import { triggerIsEditDecisionTable, triggerIsUpdateDecisionTable } from '../DecisionTable.slice';
import './PopupNotification.scss';
interface Props {
  open: boolean;
  cancel?: any;
  handleSubmit: any;
  onDiscardChange: any;
}

export const PopupConfirmSavePage = ({ open, cancel, handleSubmit, onDiscardChange }: Props) => {
  const dispatch = useAppDispatch();
  const activeGroupId = useAppSelector(state => state.decisionTableSlice.activeGroupId);

  return (
    <Modal
      width={480}
      footer={null}
      destroyOnClose={true}
      closeIcon={false}
      centered
      open={open}
      onCancel={cancel}
      className="pop-up-confirm-delete-row"
      style={{ verticalAlign: 'top', marginTop: '200px', borderRadius: 8 }}
    >
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            backgroundColor: '#002060',
            borderRadius: '100px',
            height: '60px',
            width: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src="./image/save-change.svg"
            alt="plus circle add"
            className={`icon_user-management`}
            id="confirm_licence-type__img"
          />
        </div>
      </Row>
      <Row justify="center" className="mt-10">
        <div style={{ fontSize: 20, fontWeight: 'bold' }}>Save Changes</div>
      </Row>
      <Row justify="center" style={{ display: 'flex', justifyContent: 'center' }}></Row>
      <Row gutter={[12, 12]} style={{ display: 'flex', justifyContent: 'center' }} className="mt-20">
        <Col>
          <Button className="btn-button" onClick={onDiscardChange} style={{ width: 150 }}>
            Discard Changes
          </Button>
        </Col>
        <Col>
          <Button className="btn-button" type="primary" style={{ width: 150 }} onClick={handleSubmit}>
            Save Changes
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
