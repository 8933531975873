import { createSlice } from '@reduxjs/toolkit';

export interface DecisionTableState {
  isLoading: boolean;
  isUpdateDecisionTable: boolean;
  isEditDecisionTable: boolean;
  isSaveDecisionTable: boolean;
  isShowBlockDecisionTable: boolean;
  activeGroupId: string;
}

const initialState: DecisionTableState = {
  isLoading: false,
  isUpdateDecisionTable: false,
  isEditDecisionTable: false,
  isSaveDecisionTable: false,
  isShowBlockDecisionTable: false,
  activeGroupId: '',
};

export const DecisionTableSlice = createSlice({
  name: 'Decision Table',
  initialState,
  reducers: {
    triggerIsUpdateDecisionTable: state => {
      state.isUpdateDecisionTable = !state.isUpdateDecisionTable;
    },
    triggerIsEditDecisionTable: (state, action) => {
      state.isEditDecisionTable = action.payload;
    },
    triggerIsSaveDecisionTable: (state, action) => {
      state.isSaveDecisionTable = action.payload;
    },
    triggerIsShowBlockDecisionTable: (state, action) => {
      state.isShowBlockDecisionTable = action.payload;
    },
    setActiveGroupId: (state, action) => {
      state.activeGroupId = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const {
  setActiveGroupId,
  triggerIsUpdateDecisionTable,
  triggerIsEditDecisionTable,
  triggerIsSaveDecisionTable,
  triggerIsShowBlockDecisionTable,
} = DecisionTableSlice.actions;

export default DecisionTableSlice.reducer;
