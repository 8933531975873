import { miniDashboardTestCase } from 'app/models';
import client from '.';

export const getTestCaseRegisterChartAPI = params => {
  return client.get('/TestCaseRegister/get-testcase-chart', { params: params }).then(res => res.data);
};

export const getEnginuityWorkbenchMiniDashboardAPI = () => {
  return client
    .get(`/TestCaseRegister/get-mini-dashboard?testCaseMode=${miniDashboardTestCase.enginuity_workbench_test_case}`)
    .then(res => res.data);
};

export const getTestCaseDetailAPI = (id: number) => {
  return client.get(`TestCase/get-testcase-detail?id=${id}`).then(res => res.data);
};

export const getTestCaseUsageAPI = (id: number) => {
  return client.get(`/TestCase/get-usage?testCaseId=${id}`).then(res => res.data);
};

export const getListRunHistoryAPI = (id: number, mode: any) => {
  return client.get(`/TestCase/get-run-history?testCaseId=${id}&runType=${mode}`).then(res => res.data);
};

export const deleteRunHistoryAPI = (id: number, mode: any) => {
  return client.delete(`/TestCase/delete-run-history?testCaseId=${id}&runType=${mode}`).then(res => res.data);
};

export const getTestCaseSettingAPI = (mode: number) => {
  return client.get(`TestCase/get-testcase-settings?testCaseMode=${mode}`).then(res => res.data);
};

export const getSettingIdAPI = (id: number) => {
  return client.get(`Common/get-new-id?type=${id}`).then(res => res.data);
};

export const addNewWorkBenchAPI = (param: any) => {
  return client.post(`TestCase/create-testcase`, { ...param });
};

export const cloneATestWorkBenchAPI = (id: number) => {
  return client.post(`TestCase/clone-testcase?id=${id}`);
};

export const createAVariantEnginuityWorkBenchAPI = (id: number) => {
  return client.post(`TestCase/create-variant-testcase?id=${id}`);
};

export const updateTestCaseAPI = (param: any) => {
  return client.post('/TestCase/update-testcase', { ...param });
};

export const deleteWorkbenchAPI = params => {
  return client.delete('/TestCase/delete-testcase', { data: params });
};

// Workflow
export const getWorkflowAPI = () => {
  return client.get(`TestCase/get-testcase-workflow`).then(res => res.data);
};

export const getWorkflowForReviewAPI = (id: number) => {
  return client.post(`TestCase/testcase-workflow-for-review?id=${id}`).then(res => res.data);
};

export const getWorkflowRetiredAPI = (param: any) => {
  return client.post(`TestCase/testcase-workflow-retired`, { ...param }).then(res => res.data);
};

export const getWorkflowAssignAPI = (title: string, param: any) => {
  return client.post(`TestCase/testcase-workflow-${title}`, { ...param }).then(res => res.data);
};

// Chatr .............
export const getTestCaseScopeAPI = (id: number) => {
  return client.get(`/TestCase/get-detail-scope?id=${id}`).then(res => res.data);
};

export const getLinkedDefectStatusAPI = (id: number) => {
  return client.get(`/TestCase/get-testcase-defect?id=${id}`).then(res => res.data);
};

export const apiGetMiniDashboard = (id: number) => {
  return client.get(`/TestCase/get-testcase-minidashboard?id=${id}`).then(res => res.data);
};

// workbench folder view api

export const assignTestCaseToFolderAPI = params => {
  return client.post('/TestCaseRegister/assign-testcase-to-folder', params);
};

export const reparentTestCaseAPI = params => {
  return client.post('/TestCaseRegister/reparent-requirement', params);
};

// end workbench folder view api

// workbench activity
export const getTestCaseHistoryAPI = (id: number) => {
  return client.get('/TestCase/get-testcase-histories', { params: { id: id } });
};
export const assignTestCaseAPI = params => {
  return client.post('/TestCaseRequirementCoverage/assign-testcase-to-requirement', params);
};

export const deleteTestCaseAPI = params => {
  return client.post('/TestCaseRequirementCoverage/delete-requirement-testcase', params);
};
export const getTestCaseTestCoverageAPI = params => {
  return client.get('/TestCaseRequirementCoverage/get-testcase-coverage', { params: params });
};
// end workbench activity

// Test Step .........
export const getTestStepAPI = (id: number) => {
  return client.get(`/TestCaseTestSteps/get-testcase-teststep?id=${id}`);
};

export const saveTestStepAPI = (params: any) => {
  return client.post(`/TestCaseTestSteps/overwrite-teststep`, { ...params });
};

export const getTestCaseIDAPI = (testCaseTypeEnum?) => {
  return client
    .get(`/TestCase/get-select-testcase${testCaseTypeEnum ? '?testCaseTypeEnum=' + testCaseTypeEnum : ''}`)
    .then(res => res.data);
};

export const getWorkbenchMiniDashboardAPI = () => {
  return client
    .get(`/TestCaseRegister/get-mini-dashboard?testCaseMode=${miniDashboardTestCase.workbench_test_case}`)
    .then(res => res.data);
};

// export const getAuthoriseWorkbenchMiniDashboardAPI = () => {
//   return client
//     .get(`/TestCaseRegister/get-mini-dashboard?testCaseMode=${miniDashboardTestCase.authorise_workbench_test_case}`)
//     .then(res => res.data);
// };
