import client from '.';

export const getListTestStepRunAPI = (testCaseRunId: number, testCaseId: number) => {
  return client
    .get(`ExecutionRun/get-test-step-runs?testCaseRunId=${testCaseRunId}&testCaseId=${testCaseId}`)
    .then(res => res.data);
};

export const updateTestStepRunStatusAPI = param => {
  return client.post(`ExecutionRun/update-test-step-run-status`, param).then(res => res.data);
};

export const getTestStepRunImageAPI = (testCaseRunId: number) => {
  return client.get(`ExecutionRun/get-test-step-run?testStepRunId=${testCaseRunId}`).then(res => res.data);
};

export const getTestStepRunDetailAPI = (testStepRunId: number, testCaseRunId: number) => {
  return client
    .get(`ExecutionRun/get-test-step-run-detail?testStepRunId=${testStepRunId}&testCaseRunId=${testCaseRunId}`)
    .then(res => res.data);
};

export const updateTestStepRun = param => {
  return client.post(`ExecutionRun/update-test-step-run`, param).then(res => res.data);
};
// Start execution  cycle
export const getYourAssignedExecutionAPI = () => {
  return client.get('ExecutionRun/get-your-assigned-execution').then(res => res.data);
};
export const selectedExecutionSetAPI = params => {
  return client.get(`ExecutionRun/selected-execution-set`, { params: params }).then(res => res.data);
};
// End execution cycle
export const createDefectAPI = params => {
  return client.post(`ExecutionRun/create-defect`, params).then(res => res.data);
};
